/* -------------------------------------------

Name: 		Ruizarch
Version:    1.0
Developer:	Nazar Miller (millerDigitalDesign)
Portfolio:  https://themeforest.net/user/millerdigitaldesign/portfolio?ref=MillerDigitalDesign

p.s. I am available for Freelance hire (UI design, web development). email: miller.themes@gmail.com

------------------------------------------- */
/*--------------------------------------------

1. common
    - main
    - typography
    - link
    - button
    - form
    - breadcrumbs
    - backgrounds
    - spaces
2. components
    - preloader
    - cursor
    - hidden elements
    - scrollbar
    - frame
    - menu button
    - menu 
    - banner
    - circle text
    - lines
    - dodecahedron
    - about
    - partners
    - services
    - team
    - social icons
    - revievs
    - blog
    - footer
    - portfolio
    - map
    - accordion
    - price
    - 404
    - images
    - page transitions


--------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css);

/* -------------------------------------------

main

------------------------------------------- */
*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
  line-height: 150%;
  overscroll-behavior: none;
  height: 100%;
  /* overflow-y: auto; */
}

@media screen and (max-width: 768px) {

  html,
  body {
    font-size: 15px;
  }
}

*::selection {
  color: inherit;
  background-color: transparent;
}

.mil-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

.container {
  pointer-events: all;
}

.mil-relative {
  position: relative;
}

.mil-o-hidden {
  overflow: hidden;
}

.mil-flex {
  display: flex;
}

.mil-vert-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .mil-mobile-hidden {
    display: none;
  }
}

section {
  position: relative;
  overflow: hidden;
}

/* -------------------------------------------

typography

------------------------------------------- */
h1,
.mil-h1,
h2,
.mil-h12,
h3,
.mil-h3,
h4,
.mil-h4,
h5,
.mil-h5,
h6,
.mil-h6 {
  font-family: "Outfit", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 500;
  line-height: 120%;
}

@media screen and (max-width: 768px) {

  h1 br,
  .mil-h1 br,
  h2 br,
  .mil-h12 br,
  h3 br,
  .mil-h3 br,
  h4 br,
  .mil-h4 br,
  h5 br,
  .mil-h5 br,
  h6 br,
  .mil-h6 br {
    display: none;
  }
}

h1,
.mil-h1 {
  font-size: 86px;
}

@media screen and (max-width: 1400px) {

  h1,
  .mil-h1 {
    font-size: 74px;
  }
}

@media screen and (max-width: 1200px) {

  h1,
  .mil-h1 {
    font-size: 72px;
  }
}

@media screen and (max-width: 992px) {

  h1,
  .mil-h1 {
    font-size: 58px;
  }
}

@media screen and (max-width: 768px) {

  h1,
  .mil-h1 {
    font-size: 34px;
  }
}

h2,
.mil-h2 {
  font-size: 68px;
  line-height: 120%;
}

@media screen and (max-width: 1200px) {

  h2,
  .mil-h2 {
    font-size: 56px;
  }
}

@media screen and (max-width: 992px) {

  h2,
  .mil-h2 {
    font-size: 44px;
  }
}

@media screen and (max-width: 768px) {

  h2,
  .mil-h2 {
    font-size: 36px;
  }
}

h3,
.mil-h3 {
  font-size: 42px;
}

@media screen and (max-width: 1200px) {

  h3,
  .mil-h3 {
    font-size: 39px;
  }
}

@media screen and (max-width: 992px) {

  h3,
  .mil-h3 {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {

  h3,
  .mil-h3 {
    font-size: 30px;
  }
}

h4,
.mil-h4 {
  font-size: 28px;
}

@media screen and (max-width: 1200px) {

  h4,
  .mil-h4 {
    font-size: 26px;
  }
}

@media screen and (max-width: 992px) {

  h4,
  .mil-h4 {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {

  h4,
  .mil-h4 {
    font-size: 22px;
  }
}

h5,
.mil-h5 {
  font-size: 20px;
  line-height: 150%;
}

@media screen and (max-width: 768px) {

  h5,
  .mil-h5 {
    font-size: 18px;
  }
}

h6,
.mil-h6 {
  font-size: 18px;
  line-height: 150%;
}

@media screen and (max-width: 1200px) {

  h6,
  .mil-h6 {
    font-size: 17px;
  }
}

@media screen and (max-width: 992px) {

  h6,
  .mil-h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {

  h6,
  .mil-h6 {
    font-size: 21px;
  }
}

.mil-no-wrap {
  white-space: nowrap;
}

blockquote {
  padding: 60px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-style: italic;
  border-left: solid 4px rgb(0, 168, 181);
}

@media screen and (max-width: 768px) {
  blockquote {
    padding: 30px;
    font-size: 16px;
  }
}

.mil-text-sm {
  font-size: 15px;
}

.mil-text-lg {
  font-size: 18px;
  line-height: 170%;
}

.mil-text-xl {
  font-size: 22px;
  line-height: 180%;
}

@media screen and (max-width: 768px) {
  .mil-text-xl {
    font-size: 20px;
  }
}

.mil-upper {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
}

.mil-bold {
  font-weight: 500;
}

.mil-thin {
  font-weight: 100;
}

a {
  color: inherit;
  text-decoration: none;
}

.mil-complex-title {
  line-height: normal;
}

.mil-accent {
  color: rgb(0, 168, 181);
}

.mil-light {
  color: rgb(255, 255, 255) !important;
}

.mil-light a {
  color: rgb(255, 255, 255) !important;
}

.mil-muted {
  color: rgba(255, 255, 255, 0.9);
}

.mil-dark {
  color: rgb(0, 0, 0);
}

.mil-light-soft {
  color: rgba(255, 255, 255, 0.4);
}

.mil-dark-soft {
  color: rgba(0, 0, 0, 0.5);
}

.mil-marker {
  background-color: rgb(0, 168, 181);
  padding: 0 5px;
}

.mil-center {
  text-align: center;
}

.mil-left {
  text-align: left;
}

.mil-right {
  text-align: right;
}

@media screen and (max-width: 576px) {
  .mil-sm-center {
    text-align: center;
  }
}

.mil-suptitle {
  position: relative;
  display: block;
  margin-bottom: 60px;
}

.mil-suptitle:before {
  content: "";
  position: absolute;
  right: calc(100% + 30px);
  top: 10px;
  width: 600px;
  height: 1px;
  background-color: rgb(255, 255, 255);
  opacity: 0.2;
}

.mil-suptitle.mil-suptitle-right {
  text-align: right;
}

.mil-suptitle.mil-suptitle-right:before {
  left: calc(100% + 30px);
}

@media screen and (max-width: 768px) {
  .mil-suptitle.mil-suptitle-right {
    text-align: center;
  }

  .mil-suptitle.mil-suptitle-right:before {
    display: none;
  }
}

.mil-suptitle.mil-suptitle-dark:before {
  background-color: rgb(0, 0, 0);
  opacity: 0.1;
}

@media screen and (max-width: 768px) {
  .mil-suptitle {
    margin-bottom: 60px;
  }
}

.mil-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.mil-img-frame {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 140%;
}

.mil-img-frame img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

/* -------------------------------------------

link

------------------------------------------- */
.mil-link {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}

.mil-link svg {
  margin-left: 15px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-link svg path {
  fill: rgb(255, 255, 255);
}

.mil-link.mil-accent svg {
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-link.mil-accent svg path {
  fill: rgb(0, 168, 181);
}

.mil-link.mil-dark svg {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-link.mil-dark svg path {
  fill: rgb(0, 0, 0);
}

.mil-link:hover svg {
  transform: scale(1.15);
}

.mil-link.mil-down-arrow svg {
  transform: rotate(90deg);
}

.mil-link.mil-down-arrow:hover svg {
  transform: scale(1.15) rotate(90deg);
}

.mil-link.mil-icon-left {
  display: flex;
  flex-direction: row-reverse;
}

.mil-link.mil-icon-left svg {
  margin-left: 0;
  margin-right: 15px;
  transform: rotate(180deg);
}

.mil-link.mil-icon-left:hover svg {
  transform: scale(1.15) rotate(180deg);
}

/* -------------------------------------------

button

------------------------------------------- */
.mil-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 2px;
  font-size: 12px;
  background-color: rgb(0, 168, 181);
  color: rgb(0, 0, 0);
  border-radius: 70px;
  padding: 0 15px 0 50px;
  height: 70px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-button span {
  white-space: nowrap;
}

.mil-button svg {
  margin-left: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-button svg path {
  fill: rgb(0, 168, 181);
}

.mil-button.mil-icon-button {
  padding: 15px;
}

.mil-button.mil-icon-button svg {
  margin-left: 0;
}

.mil-button.mil-icon-button-sm {
  padding: 0;
  height: 40px;
}

.mil-button.mil-icon-button-sm svg {
  margin-left: 0;
  background-color: rgb(0, 168, 181);
}

.mil-button.mil-icon-button-sm svg path {
  fill: rgb(0, 0, 0);
}

.mil-button:hover {
  transform: scale(1.015);
  filter: brightness(110%);
}

.mil-button:hover svg {
  transform: scale(1.15);
}

.mil-button.mil-arrow-down svg {
  transform: rotate(90deg);
}

.mil-button.mil-arrow-down:hover {
  transform: scale(1.015);
  filter: brightness(110%);
}

.mil-button.mil-arrow-down:hover svg {
  transform: scale(1.15) rotate(90deg);
}

@media screen and (max-width: 992px) {
  .mil-button {
    height: 60px;
    padding: 0 10px 0 40px;
  }

  .mil-button svg {
    margin-left: 25px;
  }
}

/* -------------------------------------------

form

------------------------------------------- */
input,
textarea,
select {
  position: relative;
  height: 30px;
  padding: 0 30px;
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
  color: rgb(0, 0, 0);
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  resize: vertical;
}

input:focus,
textarea:focus,
select:focus {
  outline: inherit;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

textarea {
  padding: 15px 30px;
  height: 90px;
}

/* -------------------------------------------

breadcrumbs

------------------------------------------- */
.mil-breadcrumbs {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.mil-breadcrumbs:before {
  content: "";
  position: absolute;
  right: calc(100% + 30px);
  top: 10px;
  width: 100vw;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.mil-breadcrumbs li {
  list-style-type: none;
}

.mil-breadcrumbs li:after {
  content: "/";
  margin: 0 15px;
  color: rgb(0, 0, 0);
}

.mil-breadcrumbs li a {
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-breadcrumbs li a:hover {
  color: rgb(0, 168, 181);
}

.mil-breadcrumbs li:last-child {
  margin-right: 0;
  cursor: not-allowed;
}

.mil-breadcrumbs li:last-child a {
  opacity: 0.4;
  pointer-events: none;
}

.mil-breadcrumbs li:last-child:after {
  display: none;
}

.mil-breadcrumbs.mil-light:before {
  background-color: rgba(255, 255, 255, 0.1);
}

.mil-breadcrumbs.mil-light li:after {
  color: rgba(255, 255, 255, 0.9);
}

.mil-breadcrumbs.mil-light li a:hover {
  color: rgb(0, 168, 181) !important;
}

.mil-breadcrumbs.mil-center {
  justify-content: center;
}

/* -------------------------------------------

backgrounds

------------------------------------------- */
.mil-dark-bg {
  position: relative;
}

.mil-dark-bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  backdrop-filter: invert(100%);
}

.mil-dark-bg .mi-invert-fix {
  height: 100%;
  position: relative;
  pointer-events: none;
  z-index: 3;
}

.mil-dark-bg .mi-invert-fix .container {
  pointer-events: all;
}

.mil-soft-bg {
  background-color: rgb(242, 242, 242);
}

/* -------------------------------------------

spaces

------------------------------------------- */
.mil-mr-30 {
  margin-right: 30px;
}

.mil-mb-5 {
  margin-bottom: 5px;
}

.mil-mb-10 {
  margin-bottom: 10px;
}

.mil-mb-15 {
  margin-bottom: 15px;
}

.mil-mb-20 {
  margin-bottom: 15px;
}

.mil-mb-30 {
  margin-bottom: 30px;
}

.mil-mb-60 {
  margin-bottom: 60px;
}

.mil-mb-90 {
  margin-bottom: 90px;
}

.mil-mb-120 {
  margin-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .mil-mb-120 {
    margin-bottom: 90px;
  }
}

.mil-mt-suptitle-offset {
  margin-top: 70px;
}

@media screen and (max-width: 992px) {
  .mil-mt-suptitle-offset {
    margin-top: 30px;
  }
}

.mil-p-120-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .mil-p-120-120 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.mil-p-120-90 {
  padding-top: 120px;
  padding-bottom: 90px;
}

@media screen and (max-width: 992px) {
  .mil-p-120-90 {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

.mil-p-0-120 {
  padding-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .mil-p-0-120 {
    padding-bottom: 90px;
  }
}

.mil-p-120-0 {
  padding-top: 120px;
}

@media screen and (max-width: 992px) {
  .mil-p-120-0 {
    padding-top: 90px;
  }
}

.mil-p-120-60 {
  padding-top: 120px;
  padding-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .mil-p-120-60 {
    padding-top: 90px;
    padding-bottom: 30px;
  }
}

.mil-p-90-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media screen and (max-width: 992px) {
  .mil-p-90-90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.mil-p-90-120 {
  padding-top: 90px;
  padding-bottom: 120px;
}

@media screen and (max-width: 992px) {
  .mil-p-90-120 {
    padding-top: 60px;
    padding-bottom: 90px;
  }
}

.mil-p-0-90 {
  padding-bottom: 90px;
}

@media screen and (max-width: 992px) {
  .mil-p-0-90 {
    padding-bottom: 60px;
  }
}

.mil-p-0-30 {
  padding-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .mil-p-0-30 {
    padding-bottom: 0;
  }
}

.mil-p-120-30 {
  padding-top: 120px;
  padding-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .mil-p-120-30 {
    padding-top: 90px;
    padding-bottom: 0;
  }
}

.mil-adaptive-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 992px) {
  .mil-adaptive-right {
    justify-content: flex-start;
  }
}

.mil-btn-space {
  margin-right: 30px;
}

@media screen and (max-width: 500px) {
  .mil-btn-space {
    margin-right: 50px;
    margin-bottom: 30px;
  }
}

/* -------------------------------------------

preloader

------------------------------------------- */
.mil-preloader {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

.mil-preloader .mil-preloader-animation {
  opacity: 0;
  position: relative;
  height: 100vh;
  color: rgb(255, 255, 255);
}

.mil-preloader .mil-preloader-animation .mil-pos-abs {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mil-preloader .mil-preloader-animation .mil-pos-abs p {
  opacity: 0;
  margin-right: 15px;
}

@media screen and (max-width: 992px) {
  .mil-preloader .mil-preloader-animation .mil-pos-abs {
    flex-direction: column;
  }

  .mil-preloader .mil-preloader-animation .mil-pos-abs p {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.mil-preloader .mil-preloader-animation .mil-pos-abs .mil-reveal-frame {
  position: relative;
  padding: 0 30px;
}

.mil-preloader .mil-preloader-animation .mil-pos-abs .mil-reveal-frame .mil-reveal-box {
  z-index: 4;
  position: absolute;
  opacity: 0;
  height: 100%;
  background-color: rgb(0, 168, 181);
}

.mil-preloader.mil-hidden {
  pointer-events: none;
}

/* -------------------------------------------

cursor

------------------------------------------- */
.mil-ball {
  width: 20px;
  height: 20px;
  position: fixed;
  z-index: 10;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
}

.mil-ball .mil-icon-1 {
  position: absolute;
  width: 40px;
  height: 40px;
  transform: scale(0);
}

.mil-ball .mil-icon-1 svg {
  fill: rgba(255, 255, 255, 0.9);
}

.mil-ball .mil-more-text,
.mil-ball .mil-choose-text {
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: scale(0);
}

.mil-ball.mil-accent .mil-icon-1 svg {
  fill: rgb(0, 0, 0);
}

.mil-ball.mil-accent .mil-more-text,
.mil-ball.mil-accent .mil-choose-text {
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1200px) {
  .mil-ball {
    display: none;
  }
}

/* -------------------------------------------

hidden elements

------------------------------------------- */
.mil-hidden-elements .mil-dodecahedron,
.mil-hidden-elements .mil-lines,
.mil-hidden-elements .mil-arrow {
  display: none;
}

/* -------------------------------------------

scrollbar

------------------------------------------- */
::-webkit-scrollbar {
  display: none;
}

.mil-progress-track {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

.mil-progress-track .mil-progress {
  width: 4px;
  height: 0;
  background-color: rgb(0, 168, 181);
}

@media screen and (max-width: 992px) {
  .mil-progress-track {
    display: none;
  }
}

/* -------------------------------------------

frame

------------------------------------------- */
.mil-logo {
  font-size: 42px;
  font-weight: 500;
  line-height: 100%;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 992px) {
  .mil-logo {
    font-size: 36px;
  }
}

.mil-frame {
  padding: 0px;
  position: fixed;
  z-index: 8;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}


.mil-frame .mil-frame-top {
  height: 90px;
  background-color: rgba(0, 0, 0, 1);
  padding: 0 30 0 0px;
  backdrop-filter: blur(8px);
}

/* @media screen and (max-width: 1720px){
  .mil-frame-top{
    z-index: 1;
  }
} */

/* @media screen and (max-width: 12200px) {

  .mil-frame .mil-frame-top {
    height: 90px;
    background-color: rgba(0, 0, 0, 0.95);
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    padding: 0 30px;
    backdrop-filter: blur(8px);
  }
  .mil-frame .mil-frame-top .mil-logo {
    color: rgb(255, 255, 255);
  }

  .mil-frame .mil-frame-top .mil-menu-btn span,
  .mil-frame .mil-frame-top .mil-menu-btn span:after,
  .mil-frame .mil-frame-top .mil-menu-btn span:before {
    background: rgb(255, 255, 255);
  }
} */

.mil-frame .mil-frame-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 60px 50px;
}

/* @media screen and (max-width: 1200px) {
  .mil-frame .mil-frame-bottom {
    display: none;
  }
} */
.mil-frame .mil-frame-bottom .mil-current-page {
  pointer-events: none;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  width: 300px;
  color: rgb(0, 0, 0);
  transform: rotate(-90deg) translateX(138px) translateY(-138px);
}

.mil-frame .mil-frame-bottom .mil-back-to-top {
  width: 300px;
  transform: rotate(-90deg) translateX(130px) translateY(130px);
  pointer-events: all;
}

.mil-frame-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mil-frame-top .mil-logo {
  pointer-events: all;
  color: rgb(0, 0, 0);
}

/* -------------------------------------------

menu button

------------------------------------------- */
.mil-menu-btn {
  pointer-events: all;
  height: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

@media screen and (max-width: 992px) {
  .mil-menu-btn {
    transform: scale(0.85);
  }
}

.mil-menu-btn span,
.mil-menu-btn span:after,
.mil-menu-btn span:before {
  content: "";
  display: block;
  width: 28px;
  height: 2.5px;
  background: rgb(0, 0, 0);
  backface-visibility: hidden;
  transition: inherit;
}

.mil-menu-btn span {
  position: relative;
}

.mil-menu-btn span:after,
.mil-menu-btn span:before {
  position: absolute;
}

.mil-menu-btn span:before {
  top: -9px;
}

.mil-menu-btn span:after {
  width: 18px;
  top: 9px;
}

.mil-menu-btn.mil-active span {
  transform: rotate(45deg);
}

.mil-menu-btn.mil-active span:before {
  transform: translate(0px, 9px) rotate(-90deg);
}

.mil-menu-btn.mil-active span:after {
  opacity: 0;
  width: 24px;
  transform: translate(0px, -9px) rotate(-90deg);
}

/* -------------------------------------------

menu

------------------------------------------- */
.mil-menu-frame {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-menu-frame .container {
  pointer-events: none;
}

.mil-menu-frame .mil-frame-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 50px 60px;
}

@media screen and (max-width: 1200px) {
  .mil-menu-frame .mil-frame-top {
    display: none;
  }
}

.mil-menu-frame .mil-frame-top .mil-logo {
  color: rgb(255, 255, 255);
}

.mil-menu-frame .mil-frame-top .mil-menu-btn span,
.mil-menu-frame .mil-frame-top .mil-menu-btn span:after,
.mil-menu-frame .mil-frame-top .mil-menu-btn span:before {
  background-color: rgb(255, 255, 255);
}

.mil-menu-frame .mil-main-menu {
  transform: translateX(-30px);
  opacity: 0;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

@media screen and (max-width: 1200px) {
  .mil-menu-frame .mil-main-menu {
    transform: translateY(30px);
  }
}

.mil-menu-frame .mil-menu-right-frame {
  padding-left: 60px;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  border-left: solid 1px rgba(255, 255, 255, 0.1);
  opacity: 0;
  transform: translateY(30px);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-menu-frame .mil-menu-right-frame .mil-menu-right {
  padding-bottom: 60px;
}

.mil-menu-frame .mil-menu-right-frame .mil-animation-in {
  position: absolute;
  top: -320px;
  right: 0;
  opacity: 0;
  transform: translateY(-60px);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

@media screen and (max-width: 1200px) {
  .mil-menu-frame .mil-menu-right-frame {
    display: none;
  }
}

@media screen and (max-height: 800px) {
  .mil-menu-frame .mil-menu-right-frame {
    display: none;
  }
}

.mil-menu-frame.mil-active {
  opacity: 1;
  pointer-events: all;
}

.mil-menu-frame.mil-active .container {
  pointer-events: all;
}

.mil-menu-frame.mil-active .mil-main-menu {
  transform: translateX(0);
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.4s;
}

.mil-menu-frame.mil-active .mil-menu-right-frame {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.6s;
}

.mil-menu-frame.mil-active .mil-menu-right-frame .mil-animation-in {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.8s;
}

.mil-menu-list li {
  list-style-type: none;
  margin-bottom: 15px;
}

.mil-menu-list li:last-child {
  margin-bottom: 0;
}

.mil-menu-list li a {
  display: block;
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-menu-list li a:hover {
  transform: translateX(5px);
  color: rgb(255, 255, 255);
}

.mil-menu-list.mil-hori-list {
  display: flex;
}

.mil-menu-list.mil-hori-list li {
  margin-bottom: 0;
  margin-right: 30px;
}

.mil-menu-list.mil-dark li a {
  color: rgb(0, 0, 0);
}

.mil-main-menu {
  height: 100vh;
  display: flex;
  align-items: center;
}

.mil-main-menu ul {
  padding: 0;
  margin: 0;
}

.mil-main-menu ul li {
  margin-bottom: 40px;
}

.mil-main-menu ul li:last-child {
  margin-bottom: 0;
}

.mil-main-menu ul li a {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-size: 34px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

@media screen and (max-width: 992px) {
  .mil-main-menu ul li a {
    font-size: 26px;
  }
}

.mil-main-menu ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  transform: scale(0);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-main-menu ul li a:hover {
  color: rgb(255, 255, 255);
}

.mil-main-menu ul li a.mil-active {
  padding-left: 25px;
}

.mil-main-menu ul li a.mil-active:before {
  transform: scale(1);
  background-color: rgb(0, 168, 181);
}

.mil-main-menu ul li.mil-active>a {
  color: rgb(0, 168, 181);
}

.mil-main-menu ul li.mil-active>a:hover {
  color: rgb(0, 168, 181) !important;
  filter: brightness(110%);
}

.mil-main-menu ul li.mil-has-children>a:hover {
  color: rgb(255, 255, 255);
  padding-left: 25px;
}

.mil-main-menu ul li.mil-has-children>a:hover:before {
  transform: scale(1);
}

.mil-main-menu ul li.mil-has-children ul {
  padding-left: 25px;
  overflow: hidden;
  max-height: 0;
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-main-menu ul li.mil-has-children ul li {
  margin-bottom: 5px;
}

.mil-main-menu ul li.mil-has-children ul li:first-child {
  margin-top: 40px;
}

.mil-main-menu ul li.mil-has-children ul li a {
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
}

.mil-main-menu ul li.mil-has-children ul li a:before {
  display: none;
}

.mil-main-menu ul li.mil-has-children ul li a:hover {
  color: rgba(255, 255, 255, 0.8);
  transform: translateX(5px);
}

.mil-main-menu ul li.mil-has-children ul.mil-active {
  max-height: 200px;
}

@media screen and (max-width: 1200px) {
  .mil-main-menu {
    justify-content: center;
  }

  .mil-main-menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mil-main-menu ul li {
    margin-bottom: 30px;
  }

  .mil-main-menu ul li a {
    justify-content: center;
  }

  .mil-main-menu ul li a:before {
    display: none;
  }

  .mil-main-menu ul li a.mil-active {
    padding-left: 0 !important;
  }

  .mil-main-menu ul li.mil-has-children ul {
    padding-left: 0;
  }

  .mil-main-menu ul li.mil-has-children ul li:first-child {
    margin-top: 30px;
  }
}

/* -------------------------------------------

banner

------------------------------------------- */
.mil-banner {
  height: 100vh;
}

.mil-banner .container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.mil-banner .mil-banner-content {
  width: 100%;
  padding-bottom: 120px;
  position: relative;
}

@media screen and (max-width: 992px) {
  .mil-banner .mil-banner-content {
    padding-bottom: 120px;
  }
}

.mil-banner .mil-lines-place {
  position: absolute;
  left: 0;
  bottom: calc(100% + 120px);
}

.mil-inner-banner .mil-banner-content {
  padding: 150px 0 0 0;
}

@media screen and (max-width: 1200px) {
  .mil-inner-banner .mil-banner-content {
    padding: 180px 0 0 0;
  }
}

.mil-banner-personal {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}

.mil-banner-personal .mil-banner-content {
  position: relative;
  padding-top: 90px;
}

@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content {
    padding-top: 180px;
  }
}

.mil-banner-personal .mil-banner-content .mil-personal-text {
  margin-bottom: 300px;
}

@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-personal-text {
    margin-bottom: 80px;
    text-align: center;
  }
}

.mil-banner-personal .mil-banner-content .mil-banner-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px 0 90px;
  background-color: rgb(255, 255, 255);
  height: 180px;
  position: absolute;
  top: calc(100vh - 180px);
  left: 0;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel {
    flex-direction: column;
    height: auto;
    padding: 60px 30px;
  }

  .mil-banner-personal .mil-banner-content .mil-banner-panel h5 {
    margin-bottom: 60px;
    text-align: center;
    width: 80%;
  }
}

.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right {
  display: flex;
}

.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right .mil-social-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  padding: 0 50px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 70px;
  border-radius: 70px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right .mil-social-frame:hover {
  transform: scale(1.015);
}

@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right {
    flex-direction: column;
  }

  .mil-banner-personal .mil-banner-content .mil-banner-panel .mil-right .mil-social-frame {
    margin-right: 0;
    margin-bottom: 30px;
    height: 60px;
  }
}

.mil-banner-personal .mil-banner-content .mil-banner-panel .mil-button {
  margin: 0 !important;
}

@media screen and (max-width: 992px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel {
    bottom: 0;
    top: auto;
  }
}

@media screen and (max-width: 768px) {
  .mil-banner-personal .mil-banner-content .mil-banner-panel {
    position: static;
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 992px) {
  .mil-banner-personal {
    height: auto;
  }
}

.mil-portrait-frame {
  position: relative;
}

.mil-portrait-frame img {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mil-portrait-frame .mil-nimbus {
  background: radial-gradient(50% 50% at 50% 50%, rgb(0, 168, 181) 0%, rgba(250, 168, 69, 0) 100%);
  width: 100%;
  padding-bottom: 100%;
  position: absolute;
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .mil-portrait-frame {
    height: 400px;
  }

  .mil-portrait-frame img {
    object-fit: cover;
    object-position: top;
  }
}

/* -------------------------------------------

circle text

------------------------------------------- */
.mil-circle-text {
  position: absolute;
  right: 0;
  bottom: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
}

.mil-circle-text .mil-ct-svg {
  transform: scale(2);
  width: 140px;
  height: 140px;
}

.mil-circle-text .mil-ct-svg text {
  fill: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-circle-text .mil-button {
  position: absolute;
}

.mil-circle-text:hover svg text {
  fill: rgb(255, 255, 255);
}

@media screen and (max-width: 992px) {
  .mil-circle-text {
    display: none;
  }
}

/* -------------------------------------------

lines

------------------------------------------- */
.mil-lines-place .mil-lines {
  opacity: 0.05;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  .mil-lines-place .mil-lines {
    display: none;
  }
}

.mil-lines-place .mil-lines path,
.mil-lines-place .mil-lines rect,
.mil-lines-place .mil-lines line {
  stroke: rgb(0, 0, 0);
}

.mil-lines-place .mil-lines .mil-move {
  animation: move 10s linear infinite;
}

.mil-lines-place.mil-light .mil-lines {
  opacity: 0.2;
}

.mil-lines-place.mil-light .mil-lines path,
.mil-lines-place.mil-light .mil-lines rect,
.mil-lines-place.mil-light .mil-lines line {
  stroke: rgb(255, 255, 255);
}

/* -------------------------------------------

dodecahedron

------------------------------------------- */
.mil-animation-frame {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  animation: jump 10s linear infinite;
  pointer-events: none;
}

.mil-animation {
  position: absolute;
  display: inline-block;
  width: 300px;
  height: 300px;
  opacity: 0.2;
  pointer-events: none;
}

.mil-position-1 {
  top: 100px;
  right: 100px;
}

.mil-position-1 .mil-pentagon div {
  border-top: 0.1px solid rgb(255, 255, 255);
}

.mil-position-2 {
  top: -60px;
  left: 15%;
}

.mil-position-2 .mil-pentagon div {
  border-top: 1px solid rgb(255, 255, 255);
}

.mil-position-2 .mil-dodecahedron {
  animation-delay: 0.5s;
}

@media screen and (max-width: 1400px) {
  .mil-position-2 {
    display: none;
  }
}

.mil-position-3 {
  bottom: -100px;
  right: 35%;
}

.mil-position-3 .mil-pentagon div {
  border-top: 1px solid rgb(255, 255, 255);
}

.mil-position-3 .mil-dodecahedron {
  animation-delay: 1s;
}

@media screen and (max-width: 1400px) {
  .mil-position-3 {
    display: none;
  }
}

.mil-position-4 {
  top: -60px;
  right: 20%;
}

.mil-position-4 .mil-pentagon div {
  border-top: 0.1px solid rgb(255, 255, 255);
}

.mil-position-4 .mil-dodecahedron {
  animation-delay: 0.5s;
}

@media screen and (max-width: 1400px) {
  .mil-position-4 {
    display: none;
  }
}

.mil-position-4.mil-dark .mil-pentagon div {
  border-top: 0.1px solid rgb(0, 0, 0);
}

.mil-dodecahedron {
  position: relative;
  left: 100px;
  top: 40px;
  width: 100px;
  height: 223px;
  transform-style: preserve-3d;
  animation: rotate 100s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
  }

  to {
    transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
  }
}

.mil-pentagon {
  position: absolute;
  width: 100px;
}

.mil-pentagon:nth-child(1) {
  transform: rotateY(0.2turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(6) {
  bottom: 0;
  transform: rotateY(0.2turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(2) {
  transform: rotateY(0.4turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(7) {
  bottom: 0;
  transform: rotateY(0.4turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(3) {
  transform: rotateY(0.6turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(8) {
  bottom: 0;
  transform: rotateY(0.6turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(4) {
  transform: rotateY(0.8turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(9) {
  bottom: 0;
  transform: rotateY(0.8turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(5) {
  transform: rotateY(1turn) translateZ(69px) rotateX(26.5deg);
}

.mil-pentagon:nth-child(10) {
  bottom: 0;
  transform: rotateY(1turn) translateZ(-69px) rotateX(206.5deg);
}

.mil-pentagon:nth-child(11) {
  transform: translateZ(69px) rotateX(-90deg);
}

.mil-pentagon:nth-child(12) {
  bottom: 0;
  transform: translateZ(-69px) rotateX(90deg);
}

.mil-pentagon div {
  position: absolute;
  width: 100px;
  height: 69px;
  transform-origin: bottom;
}

.mil-pentagon div:nth-child(1) {
  transform: rotate(0.2turn);
}

.mil-pentagon div:nth-child(2) {
  transform: rotate(0.4turn);
}

.mil-pentagon div:nth-child(3) {
  transform: rotate(0.6turn);
}

.mil-pentagon div:nth-child(4) {
  transform: rotate(0.8turn);
}

.mil-pentagon div:nth-child(5) {
  transform: rotate(1turn);
}

@keyframes jump {
  0% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(-30px);
  }
}

/* -------------------------------------------

about

------------------------------------------- */
.mil-about-quote {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mil-about-quote .mil-avatar {
  width: 180px;
  height: auto;
  border-radius: 50%;
  position: relative;
}

.mil-about-quote .mil-avatar:after {
  content: ' " ';
  color: rgb(0, 0, 0);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(0, 168, 181);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  padding-top: 12px;
  justify-content: center;
  font-size: 36px;
}

.mil-about-quote .mil-avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.mil-about-quote .mil-quote {
  padding-left: 30px;
  width: calc(100% - 180px);
}

@media screen and (max-width: 992px) {
  .mil-about-quote .mil-avatar {
    width: 140px;
    height: auto;
  }

  .mil-about-quote .mil-avatar:after {
    padding-top: 7px;
    width: 25px;
    height: 25px;
    font-size: 24px;
  }

  .mil-about-quote .mil-quote {
    font-size: 16px;
    padding-left: 30px;
    width: calc(100% - 140px);
  }
}

.mil-about-photo {
  position: relative;
}

.mil-about-photo .mil-lines-place {
  position: absolute;
  top: -120px;
  left: -27%;
}

/* -------------------------------------------

partners

------------------------------------------- */
.mil-infinite-show .swiper-wrapper {
  transition-timing-function: linear;
}

.mil-partner-frame {
  display: block;
}

.mil-partner-frame img {
  width: 100%;
}

/* -------------------------------------------

services

------------------------------------------- */
.mil-complex-text {
  display: flex;
  align-items: center;
}

.mil-complex-text .mil-button {
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  .mil-complex-text {
    flex-direction: column;
  }

  .mil-complex-text .mil-button {
    margin-left: 0;
    margin-top: 60px;
  }
}

.mil-text-image {
  height: 80px;
  width: 250px;
  display: inline-block;
  overflow: hidden;
  border-radius: 70px;
  margin-right: 30px;
}

.mil-text-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-text-image:hover img {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .mil-text-image {
    display: none;
  }
}

.mil-service-card-sm {
  position: relative;
  display: block;
  padding: 60px 30px;
}

.mil-service-card-sm p {
  opacity: 0;
  transform: translateY(10px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-service-card-sm .mil-button {
  transform: scale(0.3);
  filter: grayscale(100%);
  opacity: 0.4;
}

.mil-service-card-sm:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 0;
  background-color: rgb(0, 168, 181);
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-service-card-sm:hover p {
  opacity: 1;
  transform: translateY(0);
}

.mil-service-card-sm:hover .mil-button {
  transform: scale(1);
  filter: grayscale(0);
  opacity: 1;
}

.mil-service-card-sm:hover:before {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .mil-service-card-sm p {
    opacity: 1;
    transform: translateY(0);
  }

  .mil-service-card-sm .mil-button {
    transform: scale(1);
    filter: grayscale(0);
    opacity: 1;
  }
}

.mil-services-grid {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.mil-services-grid .mil-services-grid-item {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

.mil-services-grid .mil-services-grid-item:first-child {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 992px) {
  .mil-services-grid {
    padding-bottom: 90px;
  }

  .mil-services-grid .mil-services-grid-item {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  .mil-services-grid .mil-services-grid-item:nth-child(3) {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
  }
}

@media screen and (max-width: 768px) {
  .mil-services-grid {
    padding-bottom: 90px;
  }

  .mil-services-grid .mil-services-grid-item {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
}

.mil-service-card-lg {
  display: block;
}

.mil-service-card-lg .mil-descr {
  padding-right: 30px;
}

.mil-service-card-lg.mil-offset {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .mil-service-card-lg.mil-offset {
    margin-top: 0;
  }
}

@media screen and (max-width: 992px) {
  .mil-service-card-lg {
    margin-bottom: 60px;
  }
}

.mil-service-card-lg.mil-other-card {
  overflow: hidden;
  position: relative;
  border: solid 1px rgb(229, 229, 229);
  padding: 60px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-service-card-lg.mil-other-card .mil-descr {
  padding-right: 0;
}

.mil-service-card-lg.mil-other-card:hover {
  background-color: rgb(0, 168, 181);
}

.mil-service-card-lg.mil-other-card:hover .mil-link svg {
  background-color: rgb(0, 0, 0);
}

.mil-service-card-lg.mil-other-card:hover .mil-link svg path {
  fill: rgb(0, 168, 181);
}

@media screen and (max-width: 1200px) {
  .mil-service-card-lg.mil-other-card {
    padding: 30px;
  }
}

.mil-service-list li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  list-style-type: none;
  padding: 15px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
}

.mil-service-list li:first-child {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.mil-service-list.mil-light li {
  color: rgba(255, 255, 255, 0.6);
}

.mil-service-list.mil-dark li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.mil-service-list.mil-dark li:first-child {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

/* -------------------------------------------

team

------------------------------------------- */
.mil-team-card {
  position: relative;
  overflow: hidden;
  padding-bottom: 130%;
}

.mil-team-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 0;
  background-color: rgb(0, 168, 181);
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-team-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transform: scale(1.05);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-team-card.mil-offset-card {
  transform: translateY(60px);
}

@media screen and (max-width: 992px) {
  .mil-team-card.mil-offset-card {
    transform: none;
  }
}

.mil-team-card .mil-description {
  opacity: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
  transition: 0.6s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-team-card .mil-description .mil-secrc-text {
  opacity: 0;
  transform: translateY(15px);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  transition-delay: 0s;
}

.mil-team-card .mil-description .mil-secrc-text a {
  color: rgb(255, 255, 255);
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-team-card .mil-description .mil-secrc-text a:hover {
  color: rgb(0, 168, 181);
}

.mil-team-card:hover img {
  transform: scale(1);
}

.mil-team-card:hover:after {
  width: 100%;
}

.mil-team-card:hover .mil-description {
  opacity: 1;
  pointer-events: all;
}

.mil-team-card:hover .mil-description .mil-secrc-text {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.mil-team-list {
  position: relative;
}

.mil-team-list .mil-lines-place {
  position: absolute;
  top: -120px;
  left: -22.5%;
}

/* -------------------------------------------

social icons

------------------------------------------- */
.mil-social-icons {
  padding: 0;
  margin: 0;
  display: flex;
}

.mil-social-icons.mil-center {
  justify-content: center;
}

.mil-social-icons li {
  list-style-type: none;
  margin-right: 15px;
}

.mil-social-icons li:last-child {
  margin-right: 0;
}

.mil-social-icons li a {
  color: rgb(255, 255, 255);
  font-size: 18px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-social-icons li a:hover {
  color: rgb(0, 168, 181);
}

.mil-social-icons.mil-dark li a {
  color: rgb(0, 0, 0);
}

.mil-social-icons.mil-dark li a:hover {
  color: rgb(0, 168, 181);
}

/* -------------------------------------------

revievs

------------------------------------------- */
.mil-revi-pagination {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .mil-revi-pagination {
    flex-wrap: wrap;
  }
}

.mil-revi-pagination .swiper-pagination-bullet {
  padding: 0;
  width: 110px;
  height: 110px;
  padding: 10px;
  opacity: 1;
  border: none;
  background-color: transparent;
  margin: 0 !important;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1) !important;
}

@media screen and (max-width: 992px) {
  .mil-revi-pagination .swiper-pagination-bullet {
    width: 90px;
    height: 90px;
  }
}

.mil-revi-pagination .swiper-pagination-bullet:nth-child(2n) {
  margin-top: 30px !important;
}

@media screen and (max-width: 992px) {
  .mil-revi-pagination .swiper-pagination-bullet:nth-child(2n) {
    margin-top: 0 !important;
  }
}

.mil-revi-pagination .swiper-pagination-bullet:hover {
  box-shadow: inset 0 0 0 4px rgb(255, 255, 255);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot {
  background-color: red;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: top;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1) !important;
}

@media screen and (max-width: 992px) {
  .mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot {
    width: 70px;
    height: 70px;
  }
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-1 {
  background-image: url(../../../public/img/faces/customers/1.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-2 {
  background-image: url(../../../public/img/faces/customers/2.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-3 {
  background-image: url(../../../public/img/faces/customers/3.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-4 {
  background-image: url(../../../public/img/faces/customers/4.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-5 {
  background-image: url(../../../public/img/faces/customers/5.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-6 {
  background-image: url(../../../public/img/faces/customers/6.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet .mil-custom-dot.mil-slide-7 {
  background-image: url(../../../public/img/faces/customers/7.jpg);
}

.mil-revi-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  box-shadow: inset 0 0 0 4px rgb(0, 168, 181);
}

.mil-quote-icon {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
}

.mil-quote-icon path {
  fill: rgb(0, 168, 181);
}

.mil-slider-nav {
  display: flex;
}

.mil-slider-nav .mil-slider-arrow {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-slider-nav .mil-slider-arrow svg path {
  fill: rgb(0, 168, 181);
}

.mil-slider-nav .mil-slider-arrow.mil-prev {
  margin-right: 10px;
  transform: rotate(180deg);
}

.mil-slider-nav .mil-slider-arrow.swiper-button-disabled {
  opacity: 0.1;
  cursor: not-allowed;
}

.mil-slider-nav .mil-slider-arrow:hover {
  transform: scale(1.15);
}

.mil-slider-nav .mil-slider-arrow:hover.mil-prev {
  transform: rotate(180deg) scale(1.15);
}

.mil-slider-nav.mil-reviews-nav {
  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .mil-slider-nav.mil-reviews-nav {
    top: 0;
  }
}

.mil-slider-nav.mil-soft .mil-slider-arrow {
  background-color: rgb(229, 229, 229);
}

.mil-slider-nav.mil-soft .mil-slider-arrow svg path {
  fill: rgb(0, 0, 0);
}

/* -------------------------------------------

blog

------------------------------------------- */
.mil-blog-card {
  display: block;
}

.mil-blog-card .mil-cover-frame {
  position: relative;
  overflow: hidden;
  padding-bottom: 65%;
  margin-bottom: 30px;
}

.mil-blog-card .mil-cover-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-blog-card .mil-post-descr {
  padding: 30px 30px 0 0;
}

.mil-blog-card .mil-post-descr .mil-post-text {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mil-blog-card:hover .mil-cover-frame img {
  transform: scale(1.05);
}

.mil-blog-card.mil-blog-card-hori {
  padding-top: 60px;
  border-top: solid 1px rgb(229, 229, 229);
  display: flex;
  align-items: center;
}

.mil-blog-card.mil-blog-card-hori .mil-cover-frame {
  margin-bottom: 0;
  width: 450px;
  padding-bottom: 27%;
}

.mil-blog-card.mil-blog-card-hori .mil-post-descr {
  width: calc(100% - 450px);
  padding: 0;
  padding-left: 60px;
}

@media screen and (max-width: 992px) {
  .mil-blog-card.mil-blog-card-hori {
    display: block;
    padding-top: 0;
    border: none;
  }

  .mil-blog-card.mil-blog-card-hori .mil-cover-frame {
    width: 100%;
    padding-bottom: 65%;
    margin-bottom: 30px;
  }

  .mil-blog-card.mil-blog-card-hori .mil-post-descr {
    width: 100%;
    padding: 30px 30px 0 0;
  }
}

.mil-labels {
  display: flex;
  align-items: center;
  padding: 0;
}

.mil-labels .mil-label {
  margin-right: 15px;
}

.mil-labels .mil-label:last-child {
  margin-right: 0;
}

.mil-pagination {
  padding-top: 60px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mil-pagination-btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  margin-right: 10px;
}

.mil-pagination-btn.mil-active {
  background-color: rgb(0, 168, 181);
}

.mil-category-list {
  display: flex;
}

.mil-category-list li {
  list-style-type: none;
  margin-right: 10px;
}

.mil-category-list li:last-child {
  margin-right: 0;
}

.mil-category-list li a {
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0 20px;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-category-list li a:hover {
  background-color: rgb(229, 229, 229);
}

.mil-category-list li a.mil-active {
  background-color: rgb(0, 168, 181);
}

@media screen and (max-width: 768px) {
  .mil-category-list {
    flex-direction: column;
  }

  .mil-category-list li {
    margin-right: 0;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .mil-category-list li:last-child {
    margin-bottom: 0;
  }

  .mil-category-list li a {
    justify-content: flex-start;
  }
}

/* -------------------------------------------

footer

------------------------------------------- */
.mil-footer-menu {
  margin-bottom: 120px;
}

.mil-footer-menu ul li {
  list-style-type: none;
  margin-bottom: 30px;
}

.mil-footer-menu ul li a {
  display: block;
  font-weight: 500;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.9);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-footer-menu ul li a:hover {
  transform: translateX(10px);
  color: rgb(0, 168, 181);
}

@media screen and (max-width: 768px) {
  .mil-footer-menu ul li a {
    font-size: 22px;
  }
}

.mil-footer-menu ul li.mil-active a {
  color: rgb(0, 168, 181);
}

.mil-footer-menu ul li.mil-active a:hover {
  transform: none;
}

@media screen and (max-width: 768px) {
  .mil-footer-menu {
    margin-bottom: 60px;
  }
}

.mil-subscribe-form {
  position: relative;
  height: 70px;
  width: 100%;
}

.mil-subscribe-form input {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  border-radius: 70px;
  padding: 0 0 0 50px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-subscribe-form input::placeholder {
  color: rgb(128, 128, 128);
  font-family: "Outfit", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.mil-subscribe-form input:focus {
  background-color: rgb(255, 255, 255);
  outline: inherit;
}

.mil-subscribe-form input:hover {
  background-color: rgb(255, 255, 255);
}

.mil-subscribe-form .mil-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.mil-subscribe-form.mil-subscribe-form-2 input {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

/* -------------------------------------------

portfolio

------------------------------------------- */
.mil-portfolio-item {
  display: block;
}

.mil-portfolio-item .mil-cover-frame {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.mil-portfolio-item .mil-cover-frame .mil-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mil-portfolio-item .mil-cover-frame .mil-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-portfolio-item .mil-cover-frame.mil-vert {
  padding-bottom: 130%;
}

.mil-portfolio-item .mil-cover-frame.mil-hori {
  padding-bottom: 65%;
}

.mil-portfolio-item:hover .mil-cover-frame .mil-cover img {
  transform: scale(1.05);
}

.mil-portfolio-item.mil-slider-item .mil-cover-frame {
  margin-bottom: 30px;
  height: calc(100vh - 360px);
}

@media screen and (max-width: 992px) {
  .mil-portfolio-item.mil-slider-item .mil-cover-frame {
    height: auto;
    padding-bottom: 100%;
  }
}

.mil-portfolio-item.mil-slider-item .mil-descr {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 180px;
  background-color: rgb(242, 242, 242);
  padding: 0 60px;
}

@media screen and (max-width: 992px) {
  .mil-portfolio-item.mil-slider-item .mil-descr {
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .mil-portfolio-item.mil-slider-item .mil-descr .mil-descr-text {
    margin-bottom: 30px;
  }
}

.mil-portfolio-item.mil-slider-item:hover .mil-cover-frame .mil-cover img {
  transform: none;
}

.swiper-slide .mil-portfolio-item .mil-descr {
  pointer-events: none;
}

.swiper-slide.swiper-slide-active .mil-portfolio-item .mil-descr {
  pointer-events: all;
}

.mil-portfolio {
  position: relative;
}

.mil-portfolio .mil-lines-place {
  position: absolute;
  left: 40.7%;
}

.mil-portfolio .mil-lines-place.mil-lines-long {
  top: 1289px;
}

.mil-portfolio-slider-frame {
  z-index: 0;
  padding-top: 150px;
}

@media screen and (max-width: 992px) {
  .mil-portfolio-slider-frame {
    padding-top: 180px;
  }
}

.mil-portfolio-nav {
  background-color: rgb(242, 242, 242);
  width: 100%;
  padding: 0 60px;
  height: 180px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mil-portfolio-nav .mil-portfolio-btns-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 70px;
  border-radius: 70px;
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 992px) {
  .mil-portfolio-nav {
    position: static;
    height: auto;
    padding: 30px 0 90px;
    justify-content: flex-start;
    background-color: rgb(255, 255, 255);
  }

  .mil-portfolio-nav .mil-portfolio-btns-frame {
    width: 100%;
    height: auto;
    padding: 0;
  }
}

.swiper-portfolio-pagination {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0 15px;
}

.mil-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .mil-info {
    flex-direction: column;
    height: auto;
    padding: 30px;
  }
}

.mil-works-nav {
  padding-top: 60px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mil-works-nav .mil-disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .mil-works-nav {
    padding-top: 0;
    border-top: none;
    justify-content: center;
  }

  .mil-works-nav .mil-link {
    display: none;
  }

  .mil-works-nav .mil-link:last-child {
    display: flex;
  }
}

/***************************

map

***************************/
.mil-map-frame {
  background-color: rgb(229, 229, 229);
  pointer-events: all;
  height: 600px;
  position: relative;
  overflow: hidden;
}

.mil-map-frame .mil-map {
  position: absolute;
  top: -25%;
  left: -25%;
  width: 150%;
  height: 150%;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-map-frame .mil-map iframe {
  width: 100%;
  height: 100%;
}

/* -------------------------------------------

accordion

------------------------------------------- */
.mil-accordion-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}

.mil-accordion-group .mil-accordion-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 30px;
}

.mil-accordion-group .mil-accordion-menu .mil-symbol {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: rgb(0, 0, 0);
  background-color: rgb(229, 229, 229);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mil-accordion-group .mil-accordion-menu .mil-symbol .mil-plus,
.mil-accordion-group .mil-accordion-menu .mil-symbol .mil-minus {
  position: absolute;
}

.mil-accordion-group .mil-accordion-menu .mil-symbol .mil-minus {
  transform: translateY(-2px);
}

.mil-accordion-group .mil-accordion-menu .mil-accordion-head {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.mil-accordion-content {
  height: 0;
  overflow: hidden;
}

/***************************

price

***************************/
.mil-price-card {
  display: block;
  width: 100%;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding: 60px 0 30px;
}

.mil-price-card .mil-price-number {
  padding-top: 10px;
  line-height: 70%;
  font-size: 54px;
  font-weight: 500;
}

.mil-price-card .mil-price-number .mil-thin {
  font-weight: 200;
  font-size: 18px;
}

/***************************

404

***************************/
.mil-404-banner .mil-animation-frame {
  z-index: 2;
}

.mil-404-frame {
  padding-top: 100px;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mil-404-text {
  display: block;
  text-align: center;
  color: rgb(255, 255, 255);
}

.mil-scale-frame {
  transform: scale(2.3);
  text-align: center;
  margin-bottom: 90px;
}

@media screen and (max-width: 530px) {
  .mil-scale-frame {
    transform: scale(1.3);
    margin-bottom: 50px;
  }
}

.mil-404 {
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 6em;
  font-weight: 500;
  animation: glitch-skew 1s infinite linear alternate-reverse;
}

.mil-404::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: 2px;
  text-shadow: -2px 0 rgb(0, 168, 181);
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}

.mil-404::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: -2px;
  text-shadow: -2px 0 rgb(153, 153, 153), 2px 2px rgb(229, 229, 229);
  animation: glitch-anim2 1s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
  0% {
    clip: rect(53px, 9999px, 99px, 0);
    transform: skew(0.45deg);
  }

  5% {
    clip: rect(80px, 9999px, 29px, 0);
    transform: skew(0.7deg);
  }

  10% {
    clip: rect(98px, 9999px, 98px, 0);
    transform: skew(0.33deg);
  }

  15% {
    clip: rect(80px, 9999px, 48px, 0);
    transform: skew(0.25deg);
  }

  20% {
    clip: rect(53px, 9999px, 99px, 0);
    transform: skew(0.02deg);
  }

  25% {
    clip: rect(89px, 9999px, 56px, 0);
    transform: skew(0.92deg);
  }

  30% {
    clip: rect(87px, 9999px, 10px, 0);
    transform: skew(0.94deg);
  }

  35% {
    clip: rect(61px, 9999px, 39px, 0);
    transform: skew(0.62deg);
  }

  40% {
    clip: rect(29px, 9999px, 33px, 0);
    transform: skew(0.29deg);
  }

  45% {
    clip: rect(20px, 9999px, 29px, 0);
    transform: skew(0.76deg);
  }

  50% {
    clip: rect(87px, 9999px, 25px, 0);
    transform: skew(0.17deg);
  }

  55% {
    clip: rect(48px, 9999px, 10px, 0);
    transform: skew(0.4deg);
  }

  60% {
    clip: rect(53px, 9999px, 28px, 0);
    transform: skew(0.19deg);
  }

  65% {
    clip: rect(64px, 9999px, 88px, 0);
    transform: skew(0.55deg);
  }

  70% {
    clip: rect(61px, 9999px, 28px, 0);
    transform: skew(0.86deg);
  }

  75% {
    clip: rect(26px, 9999px, 48px, 0);
    transform: skew(0.81deg);
  }

  80% {
    clip: rect(97px, 9999px, 71px, 0);
    transform: skew(0.75deg);
  }

  85% {
    clip: rect(32px, 9999px, 93px, 0);
    transform: skew(0.08deg);
  }

  90% {
    clip: rect(84px, 9999px, 60px, 0);
    transform: skew(0.89deg);
  }

  95% {
    clip: rect(27px, 9999px, 24px, 0);
    transform: skew(0.71deg);
  }

  100% {
    clip: rect(6px, 9999px, 89px, 0);
    transform: skew(0.44deg);
  }
}

@keyframes glitch-anim2 {
  0% {
    clip: rect(75px, 9999px, 17px, 0);
    transform: skew(0.78deg);
  }

  5% {
    clip: rect(79px, 9999px, 3px, 0);
    transform: skew(0.25deg);
  }

  10% {
    clip: rect(19px, 9999px, 85px, 0);
    transform: skew(0.03deg);
  }

  15% {
    clip: rect(97px, 9999px, 5px, 0);
    transform: skew(0.78deg);
  }

  20% {
    clip: rect(53px, 9999px, 42px, 0);
    transform: skew(0.58deg);
  }

  25% {
    clip: rect(72px, 9999px, 2px, 0);
    transform: skew(0.4deg);
  }

  30% {
    clip: rect(55px, 9999px, 53px, 0);
    transform: skew(0.19deg);
  }

  35% {
    clip: rect(60px, 9999px, 54px, 0);
    transform: skew(0.16deg);
  }

  40% {
    clip: rect(15px, 9999px, 86px, 0);
    transform: skew(0.56deg);
  }

  45% {
    clip: rect(69px, 9999px, 73px, 0);
    transform: skew(0.59deg);
  }

  50% {
    clip: rect(57px, 9999px, 29px, 0);
    transform: skew(0.69deg);
  }

  55% {
    clip: rect(59px, 9999px, 68px, 0);
    transform: skew(0.62deg);
  }

  60% {
    clip: rect(74px, 9999px, 47px, 0);
    transform: skew(0.15deg);
  }

  65% {
    clip: rect(48px, 9999px, 63px, 0);
    transform: skew(0.5deg);
  }

  70% {
    clip: rect(90px, 9999px, 23px, 0);
    transform: skew(0.8deg);
  }

  75% {
    clip: rect(51px, 9999px, 70px, 0);
    transform: skew(0.69deg);
  }

  80% {
    clip: rect(29px, 9999px, 5px, 0);
    transform: skew(0.09deg);
  }

  85% {
    clip: rect(19px, 9999px, 63px, 0);
    transform: skew(0.89deg);
  }

  90% {
    clip: rect(62px, 9999px, 56px, 0);
    transform: skew(0.39deg);
  }

  95% {
    clip: rect(43px, 9999px, 89px, 0);
    transform: skew(0.1deg);
  }

  100% {
    clip: rect(73px, 9999px, 48px, 0);
    transform: skew(0.83deg);
  }
}

@keyframes glitch-skew {
  0% {
    transform: skew(-3deg);
  }

  10% {
    transform: skew(0deg);
  }

  20% {
    transform: skew(1deg);
  }

  30% {
    transform: skew(5deg);
  }

  40% {
    transform: skew(2deg);
  }

  50% {
    transform: skew(2deg);
  }

  60% {
    transform: skew(-1deg);
  }

  70% {
    transform: skew(-4deg);
  }

  80% {
    transform: skew(-2deg);
  }

  90% {
    transform: skew(-1deg);
  }

  100% {
    transform: skew(-4deg);
  }
}

/***************************

images

***************************/
.mil-image-frame {
  position: relative;
  overflow: hidden;
}

.mil-image-frame>img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.mil-image-frame.mil-horizontal {
  padding-bottom: 65%;
}

.mil-image-frame.mil-vertical {
  padding-bottom: 140%;
}

.mil-image-frame.mil-square {
  padding-bottom: 100%;
}

.mil-image-frame.mil-fw {
  padding-bottom: 50%;
}

.mil-image-frame .mil-zoom-btn {
  opacity: 0;
  transform: translateY(15px);
  position: absolute;
  top: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.mil-image-frame .mil-zoom-btn:hover {
  background-color: rgb(255, 255, 255);
}

.mil-image-frame .mil-zoom-btn img {
  height: 17px;
  width: 17px;
}

.mil-image-frame:hover .mil-zoom-btn {
  opacity: 1;
  transform: translateY(0);
  z-index: 2;
}

/***************************

page transitions

***************************/
.mil-frame {
  opacity: 1;
  transform: scale(1);
  transition: 0.6s;
}

.mil-curtain {
  position: fixed;
  pointer-events: none;
  z-index: 4;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  width: 100%;
  height: 100vh;
  transition: 0.6s;
}

.mil-main-transition {
  transition: 0.6s;
  margin-top: 0;
  transition-delay: 0.3s;
}

.mil-main-transition .mil-animation-frame {
  opacity: 1;
  margin-top: 0;
  transition: 0.6s;
  transition-delay: 0.3s;
}

.mil-main-transition .mil-lines-place {
  opacity: 1;
  margin-top: 0;
  transition: 0.6s;
  transition-delay: 0.3s;
}

html.is-animating .mil-frame {
  opacity: 0;
  transition-delay: 0.5s;
  transform: scale(0.9);
}

@media screen and (max-width: 1200px) {
  html.is-animating .mil-frame {
    transform: scale(1);
    opacity: 1;
  }
}

html.is-animating .mil-curtain {
  opacity: 1;
  pointer-events: all;
}

html.is-animating .mil-main-transition {
  margin-top: 0.1px;
}

html.is-animating .mil-main-transition .mil-animation-frame {
  opacity: 0;
  margin-top: -90px;
  transition-delay: 0s;
}

html.is-animating .mil-main-transition .mil-lines-place {
  opacity: 0;
  margin-top: 90px;
  transition-delay: 0s;
}

html.is-animating .mil-menu-frame {
  opacity: 0;
  transition-delay: 0.3s;
}

/***************************

carousel

***************************/
/* .carousel-image {
  max-height: 500px;
  height: auto;
  display: block;
  margin: 0 auto;
  transition: transform 1s ease;
} */

.carousel-image {
  width: 100%;
  height: auto;
  max-height: 600px;
  /* You can adjust this value based on your design */
  object-fit: cover;
  /* Ensures the image fills the carousel area nicely */
}

.carousel-image:hover {
  transform: scale(1.05);
}




/*
CUSTOM ARROWS - SLIDER
*/
.custom-arrow {
  cursor: pointer;
}

.next-arrow, .prev-arrow {
  position: absolute;
  bottom: -80px; /* Pozice pod sliderem */
  z-index: 10;
}

.next-arrow {
  right: 10px; /* Posun vpravo */
}

.prev-arrow {
  left: 10px; /* Posun vlevo */
}

.slick-dots {
  position: absolute;
  bottom: -20px; /* Posun teček nahoru, aby byly nad šipkami */
  width: 100%;
  text-align: center;
}

.hidden-on-small {
  height: auto;
}

@media (max-height: 810px) { /* Cílíme na zařízení s menší výškou než 600px */
  .hidden-on-small {
      height: 0;
      overflow: hidden;
  }
}


.mil-button-carusel {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 2px;
  font-size: 12px;
  background-color: rgb(0, 168, 181);
  color: rgb(0, 0, 0);
  border-radius: 70px;
  padding: 15px;
  height: 65px;
  text-transform: uppercase;
  font-weight: 500;
  width: 180px;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .mil-button-carusel {
    width: 100px;
    height: 50px;
  }
}

.mil-button-carusel:hover {
  transform: scale(1.015);
  filter: brightness(110%);
}


@media (max-width: 768px) {
  .mil-mobile-layout {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .mil-avatar {
    margin-bottom: 5px;
  }

  .mil-about-quote .mil-quote {
    font-size: 16px;
    padding-left: 0px;
    width: auto;
    margin-bottom: 20px;
  }
}